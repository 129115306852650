<template>
  <DatePicker v-model="modelValue" mode="dateTime" :popover="{visibility: 'click'}" is24hr :minute-increment="5" @update:modelValue="updateInput">
    <template v-slot="{ inputValue, inputEvents }">
      <input
          class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300 ytm-input"
          :value="inputValue"
          v-on="inputEvents"
          :placeholder="placeholder"
      />
    </template>
  </DatePicker>
</template>

<script>
import {DatePicker} from "v-calendar";

export default {
  name: 'Datetime',
  components: {DatePicker},
  props: {
    modelValue: {},
    placeholder: {type: String},
  },
  methods: {
    updateInput(value) {
      this.$emit('update:modelValue', value);
    },
  },
};
</script>

<style scoped>
.ytm-input {
  width: 100%;
  padding: 0.75rem 1.25rem;
  background: #F3F3F3;
  border-radius: 0.6rem;
  border: none;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-style: normal;
  line-height: 116.6%;
  font-weight: 400;
  overflow: hidden;
}

.ytm-input:focus {
  outline: none;
}
</style>