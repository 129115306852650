<template>
  <div class="ytm-input-group">
    <p class="ytm-default-text ytm-input-group-title">
      {{title}}
    </p>
    <Datetime v-model="modelValue" :placeholder="placeholder" @update:modelValue="updateInput"/>
  </div>
</template>

<script>
import "@/styles/style.css";
import "./forms.css";
import Datetime from "@/components/redesign/Form/Datetime";

export default {
  name: 'DatetimeGroup',
  components: {Datetime},
  props: {
    title: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    modelValue: {},
  },
  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', event);
    },
  },
};
</script>

<style scoped>

</style>