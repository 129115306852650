<template>
    <div class="ytm-tip">
        <div>
            <div style="display: table; height: 100%">
                <div style="display: table-cell; vertical-align: middle">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="white" class="ytm-tip-icon">
                        <path d="M 25 3 C 16.730469 3 10 9.730469 10 18 C 10 24.167969 12.785156 27.140625 14.8125 29.3125 C 16.089844 30.683594 17 31.667969 17 33 L 17 38 L 24 38 L 24 23.40625 L 20.28125 19.71875 C 19.890625 19.328125 19.890625 18.671875 20.28125 18.28125 C 20.671875 17.890625 21.328125 17.890625 21.71875 18.28125 L 25 21.59375 L 28.28125 18.28125 C 28.671875 17.890625 29.328125 17.890625 29.71875 18.28125 C 30.109375 18.671875 30.109375 19.328125 29.71875 19.71875 L 26 23.40625 L 26 38 L 33 38 L 33 33 C 33 31.085938 34.105469 29.925781 35.5 28.46875 C 37.507813 26.371094 40 23.773438 40 18 C 40 9.730469 33.269531 3 25 3 Z M 17 40 L 17 43 C 17 44.652344 18.347656 46 20 46 L 21.15625 46 C 21.601563 47.722656 23.140625 49 25 49 C 26.859375 49 28.398438 47.722656 28.84375 46 L 30 46 C 31.652344 46 33 44.652344 33 43 L 33 40 Z"></path>
                    </svg>
                </div>
            </div>
        </div>
        <div style="display: table">
            <div style="display: table-cell; vertical-align: middle">
                <slot/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DefaultTip',
};
</script>

<style scoped>
.ytm-tip {
    padding: 1rem 1.5rem;
    border-radius: 1rem;
    background-color: #D55EFF;
    display: flex;
}

@keyframes ytm-tip-icon-animation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

.ytm-tip-icon {
    position: relative;
    margin-right: 1rem;
    height: 2.5rem;
    min-width: 2rem;
    animation: ytm-tip-icon-animation 3s linear infinite;
}
</style>