export function getStrDate(datetime, month, year) {
    let result = datetime.getDate() + '';
    if (month) {
        switch (datetime.getMonth()) {
            case 0:
                result += ' января';
                break;
            case 1:
                result += ' февраля';
                break;
            case 2:
                result += ' марта';
                break;
            case 3:
                result += ' апреля';
                break;
            case 4:
                result += ' мая';
                break;
            case 5:
                result += ' июня';
                break;
            case 6:
                result += ' июля';
                break;
            case 7:
                result += ' августа';
                break;
            case 8:
                result += ' сентября';
                break;
            case 9:
                result += ' октября';
                break;
            case 10:
                result += ' ноября';
                break;
            case 11:
                result += ' декабря';
                break;
        }
    }
    if (year) {
        result += ' ' + datetime.getFullYear();
    }
    return result;
}
export function getDDMMYYYY(date, dropYear) {
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    let yyyy = date.getFullYear();
    let result = '';
    switch (date.getDay()) {
        case 0:
            result = 'Вс '; break;
        case 1:
            result = 'Пн '; break;
        case 2:
            result = 'Вт '; break;
        case 3:
            result = 'Ср '; break;
        case 4:
            result = 'Чт '; break;
        case 5:
            result = 'Пт '; break;
        case 6:
            result = 'Сб '; break;
    }
    if (dd < 10) {
        result += '0' + dd;
    } else {
        result += dd;
    }
    result += '.';
    if (mm < 10) {
        result += '0' + mm;
    } else {
        result += mm;
    }
    if (!dropYear) {
        result += '.' + yyyy;
    }
    return result;
}

export function getHHMM(date) {
    let hh = date.getHours();
    let mm = date.getMinutes();
    let result = '';
    if (hh < 10) {
        result += '0' + hh;
    } else {
        result += hh;
    }
    result += ':';
    if (mm < 10) {
        result += '0' + mm;
    } else {
        result += mm;
    }
    return result
}
